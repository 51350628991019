import React, { useEffect, useState, useCallback } from "react";
import { RESULT_PER_PAGE } from "../../constants";
import { search } from "../../api/learningItems";
import I18n, { getContentLang } from "../../utils/i18n";
import Content from "../../components/layout/Content";
import { useTranslation } from "react-i18next";
import LearningItemSearch from "../../components/search/LearningItemSearch";
import Input from "../../components/Input";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import FilterTrainingModal from "../../components/modal/FilterTrainingModal";
import { useDebounce } from "../../utils";

const LEARNING_ITEM_FILTERS = [
  {
    label: "pages.search.filters.types.capsules",
    value: "capsule"
  },
  {
    label: "pages.search.filters.types.videos",
    value: "video"
  },
  {
    label: "pages.search.filters.types.files",
    value: "file"
  }
];

const Search = () => {
  const [t] = useTranslation();
  const [error, setError] = useState(null);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [keywords, setKeywords] = useState("");
  const [chosenTraining, setChosenTraining] = useState(null);
  const [types, setTypes] = useState([]);
  const [learningItems, setLearningItems] = useState([]);
  const location = useLocation();
  const [filterModal, setFilterModal] = useState(false);
  const debounceValue = useDebounce(keywords);

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params.q) {
      setKeywords(params.q.toString());
    }
  }, [location]);

  const refreshList = useCallback(async () => {
    setError(null);
    try {
      const result: [] = await search(
        currentPage * RESULT_PER_PAGE,
        RESULT_PER_PAGE,
        keywords,
        chosenTraining,
        types
      );
      setHasMoreItems(result.length >= RESULT_PER_PAGE - 1);
      if (currentPage === 0) {
        setLearningItems(result);
      } else {
        setLearningItems(l => [...l, ...result]);
      }
    } catch (e) {
      if (e.message) {
        setError(I18n.t(e.message, I18n.t("screens.search.refreshListFail")));
      } else {
        setError(I18n.t("screens.search.refreshListFail"));
      }
    }
  }, [chosenTraining, currentPage, keywords, types]);

  const loadMore = async () => {
    if (!hasMoreItems) {
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    refreshList();
  }, [currentPage, refreshList]);

  useEffect(() => {
    const reload = async () => {
      if (currentPage === 0) {
        refreshList();
      } else {
        setCurrentPage(0);
      }
    };

    reload();
  }, [debounceValue, chosenTraining, types, currentPage, refreshList]);

  return (
    <Content title={t("pages.search.title")} style={{ overflow: "hidden" }}>
      <FilterTrainingModal
        show={filterModal}
        setShow={setFilterModal}
        setChosenTraining={setChosenTraining}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Input
          iconLeft="search2"
          // @ts-ignore
          type="text"
          value={keywords}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setKeywords(e.target.value);
          }}
          placeholder={t("pages.search.filters.keywords")}
        />
        <Input
          iconLeft="filter"
          iconRight={chosenTraining ? "close" : "down"}
          iconRightClick={() => {
            if (chosenTraining) {
              setChosenTraining(null);
            } else setFilterModal(true);
          }}
          // @ts-ignore
          onClick={() => {
            setFilterModal(true);
          }}
          type="text"
          readOnly
          // @ts-ignore
          value={chosenTraining ? getContentLang(chosenTraining?.name) : ""}
          placeholder={t("pages.search.filters.training")}
        />
        <div className="mb-2">
          {LEARNING_ITEM_FILTERS.map(
            // @ts-ignore
            ({ label, value }, index: number) => {
              // @ts-ignore
              const isSelected = types.includes(value);
              // @ts-ignore
              return (
                <span
                  className={`inteach-tags inteach-tags--clickable ${
                    isSelected ? "inteach-tags--selected" : ""
                  }`}
                  key={index}
                  onClick={() =>
                    setTypes(
                      // @ts-ignore
                      isSelected
                        ? types.filter(item => item !== value)
                        : [...types, value]
                    )
                  }>
                  {t(label)}
                </span>
              );
            }
          )}
        </div>
      </div>
      <div
        style={{
          height: "75%",
          margin: "0 -20px",
          padding: "0 20px",
          overflow: "scroll"
        }}>
        <InfiniteScroll
          next={loadMore}
          hasMore={hasMoreItems}
          loader={
            <Loader type="BallTriangle" color="#6fd6ba" height={50} width={50} />
          }
          dataLength={learningItems.length}>
          {learningItems &&
            learningItems.map((item, index) => (
              <LearningItemSearch key={index} item={item} />
            ))}
        </InfiniteScroll>
      </div>
    </Content>
  );
};

export default Search;
