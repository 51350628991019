export default {
  common: {
    cancel: "Annuler",
    hello: "Bonjour",
    update: "Actualiser",
    understood: "J'ai compris",
    next: "Suivant",
    agree: "Accepter",
    signout: "Déconnexion",
    start: "Démarrer",
    save: "Enregistrer",
    settingUpWebapp: "Nous préparons votre webapp",
    contactUs: "Nous contacter",
    loading: "Chargement",
    seeMore: "Voir plus",
    loadMore: "Charger plus",
    yes: "Oui",
    no: "Non",
    open: "Ouvrir",
    close: "Fermer",
    french: "Français",
    english: "Anglais",
    spanish: "Espagnol",
    italian: "Italien",
    german: "Allemand",
    dutch: "Néerlandais",
    rejoin: "Rejoindre"
  },
  languages: {
    fr: "Français",
    en: "Anglais",
    es: "Espagnol",
    nl: "Néerlandais",
    de: "Allemand",
    it: "Italien"
  },
  menu: {
    home: "Accueil",
    trainings: "Vos formations",
    account: "Mon compte",
    settings: "Paramètres",
    wall: "Mon Wall"
  },
  user: {
    updateProfilePictureSuccess: "Photo de profil mis à jour !",
    errorUploadProfilePicture: "Imposible de mettre à jour la photo de profil.",
    updateProfileSuccess: "Votre profil a été mis à jour !",
    errorUpdateProfile: "Impossible de mettre à jour votre profil.",
    updatePasswordSuccess: "Votre mot de passe a été mis à jour !",
    errorUpdatePassword: "Impossible de mettre à jour votre mot de passe.",
    firstname: "Prénom",
    lastname: "Nom",
    ducrayClientRef: "Numéro client",
    ducrayPharmacyName: "Nom pharmacie",
    zipcode: "Code postal",
    city: "Ville"
  },
  amplify: {
    "Code hint":
      "Vérifiez que votre code à 4 caractères correspond aux 4 derniers caractères du lien que vous avez reçu.",
    signupEmail: "Email",
    signupPassword: "Mot de passe (min. 8 caractères)",
    "Reset your password": "Réinitialiser votre mot de passe",
    "Send Code": "Envoyer le code",
    "Code Sent": "Code envoyé",
    "Create Account": "S'inscrire",
    successSignIn: "Connexion réussie !",
    successSignOut: "Vous êtes déconnecté",
    errorSignOut: "Impossible de vous déconnecter",
    emptyPassword: "Le mot de passe doit être renseigné",
    "Resend code": "Renvoyer le code",
    "Sign in to your account": "Connexion",
    "Sign in": "Connexion",
    InvalidEmail: "Format d'email invalide",
    inscriptionCode: "Code d'inscription",
    Email: "Adresse email",
    "Create a new account": "Créer un compte",
    "Enter your username": "Nom d'utilisateur",
    "Enter your email": "Email",
    "Enter your password": "Mot de passe",
    "Temporary password has expired and must be reset by an administrator.":
      "Votre mot de passe temporaire à expiré et doit être reinitialisé par un administrateur",
    "Use QRCode": "Utiliser un QR Code",
    Submit: "Envoyer",
    Send: "Envoyer",
    "Enter your confirmation code": "Code de confirmation reçu par email.",
    "Enter your new password": "Nouveau mot de passe",
    "Incorrect username or password.": "Mot de passe incorrect",
    "User does not exist.": "Mot de passe incorrect",
    InvalidParameterExceptionEmail: "Format d'email non valide",
    UsernameExistsException: "Cette adresse email existe déjà.",
    InvalidPasswordExceptionUppercase:
      "Le mot de passe doit avoir des majuscules",
    InvalidPasswordExceptionLowercase:
      "Le mot de passe doit avoir des miniscules",
    InvalidPasswordExceptionNumeric: "Le mot de passe doit avoir des chiffres",
    InvalidPasswordExceptionLong:
      "Le mot de passe doit faire 8 caractères minimum",
    InvalidPasswordExceptionSymbol:
      "Le mot de passe doit avoir des caractères spéciaux",
    InvalidParameterExceptionGreaterthanorequal:
      "Le mot de passe doit faire 8 caractères minimum",
    InvalidParameterExceptionPasswordempty: "Le mot de passe est obligatoire",
    UserNotFoundException: "Utilisateur non trouvé",
    NotAuthorizedExceptionIncorrectusername: "Utilisateur non trouvé",
    NotAuthorizedException: "Email ou mot de passe incorrect",
    InvalidPasswordExceptionNotLongEnough:
      "Le mot ne passe doit se composer d'au moins 8 caractères",
    InvalidPasswordExceptionBadFormat:
      "Format de mot de passe invalide (ne doit ni commencer ni finir par un espace)",
    InvalidParameterException: "Une erreur est survenue",
    LimitExceededException:
      "Limite de tentatives dépassée, veuillez essayer plus tard.",
    InvalidParameterExceptionThereisnoregisteredverifiedemail:
      "Vous ne pouvez pas modifier votre mot de passe car votre compte n'est pas vérifié. Veuillez contacter votre administrateur.",
    InvalidParameterExceptionAlreadyconfirmed:
      "L'utilisateur est déjà confirmé.",
    "I've got a code": "S'inscrire avec un code",
    "Forget your password?": "Mot de passe oublié",
    inscriptionCodeHint: "Code d'inscription",
    ssoButtonTitleDoctolib: "Me connecter avec Doctolib",
    "Back doctolib login": "Revenir à la connexion Doctolib",
    Redirection: "Vous allez être redirigé vers votre fournisseur d'accès",
    CodeMismatchException: "Code de vérification invalide",
    connectionLoading: "Connexion",
    signUpLoading: "Inscription en cours",
    sendingCode: "Envoi du code",
    changingPassword: "Modification du mot de passe"
  },
  rightbar: {
    messaging: "Messagerie",
    statistics: "Statistiques",
    trainers: "Vos formateurs",
    formationTime: "Temps de formation",
    sessionNumber: "Nombre de sessions",
    mainTrainer: "Coach principal"
  },
  messaging: {
    title: "Chat",
    chat: "Chat",
    yourTrainers: "Vos formateurs",
    mainTrainer: "Formateur principal",
    nothingYet: "Démarrez la conversation",
    loadingChannels: "Chargement des messages ...",
    writeMessage: "Message",
    sendMessage: "Envoyer",
    Commands: "Commandes",
    searchingPeople: "Recherche d'utilisateurs",
    uploadFile: "Ajouter un fichier",
    uploadPhoto: "Ajouter une photo",
    addFile: "Ajouter un fichier",
    cancel: "Annuler",
    chooseAction: "Choisissez une action",
    deleteMessage: "Supprimer",
    editMessage: "Éditer",
    reply: "Répondre",
    addReaction: "Réagir",
    oneReply: "1 réponse",
    countReplies: "{{ replyCount }} réponses",
    loadingMessages: "Chargement des messages ...",
    editingMessage: "Édition du message",
    startThread: "Débuter une conversation",
    startNewThread: "Début d'une nouvelle conversation",
    noChannelsYet: "Vous n'avez pas encore de chat",
    noItems: "Aucun élémént",
    messageDeleted: "Message supprimé",
    sent: "Envoyé",
    openEmojiPicker: "Ouvrir le sélecteur d'émojis",
    close: "Fermer",
    countOnline: "{{ watcherCount }} en ligne",
    limitExceeded: "Veuillez réessayer plus tard ..."
  },
  api: {
    unknownError: "Une erreur est survenue. Veuillez ré-essayer",
    errorUploadProfilePicture: "Impossible de charger votre phot de profil"
  },
  start: "Commencer",
  continue: "Continuer",
  signout: "Se déconnecter",
  "Network Error":
    "Veuillez vous assurer de l'état de votre connexion internet",
  codePushUpdateAvailable: "Mise à jour disponible",
  codePushUpdateAvailableInstall:
    "I l y a une nouvelle version disponible, voulez vous l'installer maintenant ?",
  links: {
    usingLinkInProgress: "Utilisation du lien en cours ...",
    linkUsed: "Lien utilisé !",
    linkNotAuthorized: "Vous n'êtes pas autorisé à utiliser ce lien",
    linkInternalError: "Nous n'avons pas pu appliquer le lien"
  },
  modals: {
    notifications: {
      title: "Notifications",
      empty: "Pas de notifications"
    },
    editAccount: {
      title: "Modifier mon compte"
    },
    rgpd: {
      title: "RGPD"
    },
    changePassword: {
      title: "Changer le mot de passe",
      oldPasswordLabel: "Ancien mot de passe",
      newPasswordLabel: "Nouveau mot de passe",
      newPasswordConfirmLabel: "Confirmer le mot de passe"
    },
    capsuleReward: {
      points_success_activity_interval:
        "Vous avez terminé cette activité et gagné (0){0 point};(1){1 point};",
      points_success_activity_plural:
        "Vous avez terminé cette activité et gagné {{count}} points",
      percent_correct: "{{count}}% de bonnes réponses",
      minimum_required: "Minimum requis : {{count}}%",
      congrats: "Félicitations !",
      fail: "Échec",
      reward: "Vous avez terminé cette activité et gagné ",
      failDescription:
        "Vous n'avez pas réussi pour cette fois. Réessayez pour valider cette capsule !"
    }
  },
  pages: {
    help: {
      upgrade: "Mettre à jour",
      appUpgradeNeededTitle:
        "Cette application est à l'image de ses apprenants, elle progresse ! 🎉",
      appUpgradeNeeded:
        "Pour une meilleure expérience d'apprentissage, nous vous invitons à mettre à jour cette application.",
      goBackTrainings: "Retourner aux formations",
      linkChar: `Rentrez les quatre derniers caractères du lien d'invitation dans le champ ci-dessous si vous n'avez pas pu accéder à l'écran d'inscription. (Ex : "abcd" pour le lien suivant https://{{brand}}.page.link/abcd).`,
      emptyCode: "Le champ ne peut être vide.",
      codeNotFound: "Nous n'avons pas trouvé ce lien d'inscription.",
      verifyLink: "Vérifier",
      titleNotConfirmed: "Votre compte n'est pas confirmé.",
      title: "Nous n'arrivons pas à trouver votre compte 🕵️‍♀️",
      signupCodeTitle: "Code d'inscription erroné",
      signupCodeText: `Vérifiez que le code à 4 chiffres que vous avez entré est correct. Si c'est le cas, contactez-nous en cliquant sur le bouton ci-dessous !`,
      titleExists: "Ce compte existe déjà 🔍",
      noPanic: "Pas de panique 🧘🏽‍♂",
      alreadySignup:
        "Si vous ne vous êtes jamais inscrit avec cet email contactez l'administrateur pour qu'il puisse vous re-créer un compte.",
      alreadySignupConfirmCode: `Si votre compte n'est pas confirmé et que vous souhaitez le faire, cliquez sur "Confirmer un code" puis "Renvoyer un code".`,
      didSignupError:
        "Si vous avez rencontré un problème pour vous inscrire vous pouvez nous contacter en cliquant sur le bouton ci-dessous.",
      tryLoginAgain: "Ré-essayer de me connecter",
      codeSent: "Nous venons de vous renvoyer un code",
      emailSubject: "Problème d'inscription {{brand}}",
      emailBody: `
Bonjour,

Je n'arrive pas à me connecter avec mon email : {{username}}.

Pouvez-vous m'aider ?

Merci
      `
    },
    messaging: {
      title: "Messagerie"
    },
    account: {
      title: "Mon compte",
      editButton: "Modifier mes informations",
      trainingTime: "Temps de formation",
      points: "Points d'expérience",
      sessionNumber: "Nombre de sessions cette semaine",
      time_per_user_format_hours: "{{hours}}h{{minutes}}",
      time_per_user_format_minutes: "{{minutes}}min",
      statsLoadError:
        "Nous n'avons pas réussi à charger vos statistiques, essayez de revenir plus tard"
    },
    settings: {
      title: "Paramètres",
      changePassword: "Changer le mot de passe",
      changeLanguage: "Modifier la langue",
      infosRGPD: "Informations RGPD",
      toggleNotifications: "Notifications"
    },
    trainings: {
      title: "Vos formations",
      refreshListFail: "Le chargement de vos formations a échoué",
      noTraining: "Pas de formation dans les cartons !",
      participants_interval:
        "(0){Il n'y a pas de participant};(1){1 participant};",
      participants_plural: "{{count}} participants",
      points_interval: "(0){0 point};(1){1 point};",
      points_plural: "{{count}} points",
      training_not_started: "Cet entraînement sera disponible le {{date}}"
    },
    wall: {
      title: "Actualités",
      loadingFail: "Nous ne pouvons pas charger vos posts pour le moment.",
      commentsCount: "{{count}} Commentaire",
      commentsCount_plural: "{{count}} Commentaires",
      likes: "{{count}} J'aime",
      otherLikes: "{{name}} et {{count}} autre personne ont aimé",
      otherLikes_plural: "{{name}} et {{count}} autres personnes ont aimé",
      otherLikesPeople_1: "{{firstUser}} aime",
      otherLikesPeople_2: "{{firstUser}} et {{secondUser}} ont aimé",
      otherLikesPeople_3:
        "{{firstUser}}, {{secondUser}} et {{thirdUser}} ont aimé",
      commentBoxPlaceholder: "Votre commentaire",
      seeLikes: "Voir",
      loadMoreComments: "Charger plus de commentaires",
      loadMoreLikes: "Charger plus de j'aime",
      newPosts_interval: "(1){1 nouveau post};",
      newPosts_plural: "{{count}} nouveaux posts",
      likesModal: {
        title: "Mentions j'aime ({{count}})"
      },
      firstToComment: "Soyez le premier à commenter !",
      start: "Démarrer",
      noPosts: "Pas encore de posts dans votre wall",
      loadMorePosts: "Charger plus de posts"
    },
    search: {
      title: "Recherche",
      refreshListFail: "La recherche a échoué",
      noResult: "Pas de résultat trouvé",
      searchPlaceholder: "Rechercher un contenu",
      filters: {
        keywords: "Recherche par mot clé",
        training: "Filtrer par formation",
        types: {
          capsules: "Capsules",
          videos: "Vidéos",
          files: "Fichiers"
        }
      }
    }
  },
  learningSets: {
    learningItems: {
      locked: "Terminez l’activité précédente",
      availableOn: "Disponible {{date}}",
      capsule: {
        title: "Capsule"
      },
      test: {
        title: "Évaluation"
      },
      video: {
        title: "Vidéo"
      },
      scorm: {
        title: "Package SCORM"
      },
      file: {
        title: "Fichier"
      },
      practice: {
        title: "Mise en pratique",
        moreTime: "Il me faut encore un peu de temps",
        done: "J'ai terminé !",
        confirm: "Je confirme",
        alertTitle: "Êtes-vous sûr.e ?",
        alertText:
          "Je confirme que j'ai terminé cette mise en pratique. Si un rendu est demandé par mon formateur, je lui ai envoyé"
      }
    }
  },
  oneSignal: {
    bellPrompt: {
      "tip.state.unsubscribed": "S'abonner aux notifications",
      "tip.state.subscribed": "Vous êtes abonné aux notifications",
      "tip.state.blocked": "Vous avez bloqué les notifications",
      "message.prenotify": "Cliquer pour vous abonner aux notifications",
      "message.action.subscribed": "Vous vous êtes abonné aux notifications !",
      "message.action.resubscribed":
        "Vous vous êtes abonné aux notifications !",
      "message.action.unsubscribed":
        "Vous vous êtes désabonné des notifications !",
      "dialog.main.title": "Notifications",
      "dialog.main.button.subscribe": "Abonné",
      "dialog.main.button.unsubscribe": "Se désabonner",
      "dialog.blocked.title": "Débloquer les Notifications",
      "dialog.blocked.message":
        "Suivre les instructions pour débloquer les notifications:"
    }
  }
};
