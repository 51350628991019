import * as React from "react";
import Loader from "react-loader-spinner";

export default ({
  title,
  loadingTitle = title,
  isLoading = false,
  containerClass = "",
  loaderDimensions = {
    height: 4,
    width: 20
  },
  color = "#F8F8F8"
}: {
  title: string;
  loadingTitle?: string;
  isLoading?: boolean;
  containerClass?: string;
  loaderDimensions?: {
    height?: number;
    width?: number;
  };
  color?: string;
}) => {
  return isLoading ? (
    <div
      className={containerClass}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        color: color
      }}>
      {loadingTitle}
      <Loader
        type="BallTriangle"
        color={color}
        height={loaderDimensions.height}
        width={loaderDimensions.width}
      />
    </div>
  ) : (
    <>{title}</>
  );
};
