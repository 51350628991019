export default {
  api: {
    path: "https://api.inteach.com"
  },
  apiV2: {
    path: "https://v2.api.inteach.com"
  },
  iotCore: {
    region: "eu-west-3",
    endpoint: "a28c5g8ycpkd8x-ats.iot.eu-west-3.amazonaws.com"
  },
  apiGateway:
    "https://kn3fezpipa.execute-api.eu-west-3.amazonaws.com/production"
};
