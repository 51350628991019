import create from "zustand";
import { merge } from "lodash";

import { ITraining } from "../../types/training";
import { IAction } from "../../types/action";
import { ILearningItem } from "../../types/learningItem";

interface ILearningItems {
  [k: string]: ILearningItem[];
}

type State = {
  updateTrainingProgress: boolean,
  trainings: ITraining[];
  learningItems: ILearningItems;
  addTrainings: (
    trainings: ITraining[],
    { append }: { append: boolean }
  ) => void;
  addLearningItems: (idTraining: string, actions: IAction[]) => void;
  updateLearningItem: (
    idTraining: string,
    idLearningItem: string,
    learningItemData: any
  ) => void;
  updateTraining: (training: ITraining) => void;
};

const _updateLearningItemsLock = (
  items: ILearningItem[],
  isTrainingLocked = false
) => {
  if (!isTrainingLocked) return items;

  return items.map((item, index, arr) => {
    const previousItem = arr[index - 1];

    if (!previousItem) return item;

    return {
      ...item,
      // If we don't have a previous item set is locked to false
      isLocked: !previousItem.finished
    };
  });
};

const _updateLearningItems = (
  items: ILearningItem[],
  idLearningItem: string,
  learningItemData: object
) =>
  items.map(li => {
    if (li.id_learning_item !== idLearningItem) return li;

    return {
      ...li,
      ...learningItemData,
      //@ts-ignore
      success: li.success ? li.success : learningItemData.success,
      //@ts-ignore
      finished: li.finished ? li.finished : learningItemData.finished
    };
  });

export default create<State>(

    (set, get) => ({
      trainings: [],
      learningItems: {},
      updateTrainingProgress: false,
      updateTraining: (training: ITraining) => {
        // Check if training exists
        const currentTraining = get().trainings.find(
          ({ id_training }) => training.id_training === id_training
        );

        if (!currentTraining) {
          set((state: State) => ({
            ...state,
            trainings: [training, ...state.trainings]
          }));
          return;
        }

        set((state: State) => ({
          ...state,
          trainings: state.trainings.map(t => {
            if (t.id_training !== training.id_training) return t;

            return merge({}, t, training);
          })
        }));
      },
      addTrainings: (trainings: ITraining[], { append } = { append: false }) =>
        set(state => ({
          ...state,
          trainings: append ? [...state.trainings, ...trainings] : trainings
        })),
      addLearningItems: (idTraining: string, actions: IAction[]) => {
        const learningItemsTraining = get().trainings.find(
          ({ id_training }) => id_training === idTraining
        );

        if (!learningItemsTraining) return;

        const isTrainingLocked = learningItemsTraining.settings.isLockedType;

        // From actions get an array of learningItems
        //@ts-ignore
        const items = actions.reduce((memo, action) => {
          return [...memo, ...action.items];
        }, []);
        //@ts-ignore
        set(state => ({
          ...state,
          learningItems: {
            ...state.learningItems,
            // @ts-ignore
            [idTraining]: isTrainingLocked ? _updateLearningItemsLock(items, isTrainingLocked) : items
          }
        }));
      },
      updateLearningItem: (idTraining, idLearningItem, learningItemData) => {
        // Get current training to retrieve current success
        const currentTraining = get().trainings.find(
          ({ id_training }) => id_training === idTraining
        );

        if (!currentTraining) return;

        const isTrainingLocked = currentTraining.settings.isLockedType;
        // Get training's items
        const trainingLearningItems = get().learningItems[idTraining];

        if (!trainingLearningItems) return;

        // Get if current learning item is succeeded
        const currentItemSucceeded = trainingLearningItems.find(
          ({ id_learning_item }) => id_learning_item === idLearningItem
        )?.success;

        // If user succeded the learning item we should update the training progress
        let progress = currentTraining.progress;
        if (!currentItemSucceeded && learningItemData.success) {
          const totalLearningItems = trainingLearningItems.length;
          const itemsSucceded = trainingLearningItems.filter(
            ({ success }) => success
          );

          progress = ((itemsSucceded.length + 1) * 100) / totalLearningItems;
        }

        const trainingItems = get().learningItems[idTraining];

        if (!trainingItems) return;

        const updatedLearningItems = _updateLearningItemsLock(
          _updateLearningItems(trainingItems, idLearningItem, learningItemData),
          isTrainingLocked
        );

        set(state => ({
          learningItems: {
            ...state.learningItems,
            [idTraining]: updatedLearningItems
          },
          trainings: state.trainings.map(training => {
            if (training.id_training !== idTraining) return training;

            return {
              ...training,
              //@ts-ignore
              progress: parseInt(progress)
            };
          })
        }));
      }
    }),
);
