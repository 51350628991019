import create from "zustand";
import createVanillaStore from "zustand/vanilla";
import { IUser } from "../../types/users";

export const userSelector = (fn: "addUser" | "user") => (state: State) =>
  state[fn || "addUser"];

type State = {
  user: IUser;
  addUser: (data: object) => void;
};

export const vanillaUserStore = createVanillaStore<State>(set => ({
  user: {
    id_user: "",
    email: "",
    picture: "",
    lang: "fr",
    rgpd: false,
    lastname: "",
    firstname: "",
    role: "LEARNER",
    organization_id: "",
    organization_name: "",
    points: 0
  },
  addUser: (data: object) =>
    set(state => ({
      ...state,
      user: {
        ...state.user,
        ...data
      }
    }))
}));

// Ts ignore because user is defined as an empty object
export default create<State>(vanillaUserStore);
