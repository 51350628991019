import * as moment from "moment-timezone";
import OneSignal from "react-onesignal";
import { Auth } from "aws-amplify";
import Config from "../config";
import axios from 'axios';
import * as UserAPI from "../api/users";
import { IUser } from "../../types/users";
import { setLanguage } from "./i18n";
import { vanillaUserStore } from "../stores/users";

export interface IUserProfile extends IUser {
  email: string;
  firstname: string;
  lastname: string;
  role: "ADMINISTRATOR" | "LEARNER";
  organization_id: string;
  points: number;
  picture?: string | null;
  lang: string | null;
  timezone: string | null;
  rgpd: boolean;
}

export interface IUserUpdate {
  firstname?: string;
  lastname?: string;
  role?: "ADMINISTRATOR" | "LEARNER";
  settings?: {
    attributes?: object;
    lang?: string | null;
    rgpd?: boolean;
  };
}

export const initUser = async () => {
  try {
    const user: IUserProfile = await UserAPI.getProfile();
    // get local timezone and update user
    // ( ex: for paris timezone = Europe/Paris )
    const timezone = moment.tz.guess();
    !(user.timezone && user.timezone === timezone) &&
      (await UserAPI.update({
        settings: { timezone }
      }));

    vanillaUserStore.setState({
      ...vanillaUserStore.getState(),
      user: { ...user }
    });

    if (user.lang) await setLanguage(user.lang);
    return user;
  } catch (e) {
    console.log(e);
    // User not logged
  }
};

export const userReadRGPD = async () => {
  try {
    const { user } = vanillaUserStore.getState();

    return user.rgpd;
  } catch (e) {
    // Maybe there is no user in cache
  }

  // Get profile
  const profile: IUserProfile = await UserAPI.getProfile();

  if (profile.rgpd) return true;

  try {
    vanillaUserStore.setState({
      ...vanillaUserStore.getState(),
      user: { ...profile }
    });
  } catch (e) {}

  return false;
};

export const acceptRGPD = async () => {
  try {
    await UserAPI.update({
      settings: { rgpd: true }
    });

    const { user } = vanillaUserStore.getState();

    vanillaUserStore.setState({
      ...vanillaUserStore.getState(),
      user: {
        ...user,
        rgpd: true
      }
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const updateUser = async (attributes: IUserUpdate) => {
  await UserAPI.update(attributes);

  const { user } = vanillaUserStore.getState();

  vanillaUserStore.setState({
    ...vanillaUserStore.getState(),
    user: {
      ...user,
      ...attributes
    }
  });
};

// Ajout d'une route updateUserV2 pour éviter de casser quelque chose
// Besoin urgent d'ajouter des infos pour le user à la first connection
// Pour la marque blanche Ducray
export const updateUserV2 = async (metadata: any) => {
  const apiV2Url = Config.get('apiV2.path');
  const data = await Auth.currentSession();
  const jwtToken = data.getAccessToken().getJwtToken();
  await axios.post(`${apiV2Url}/users/onboarding`, metadata, {
    headers: {
      authorization: `Bearer: ${jwtToken}`
    }
  })

  const { user } = vanillaUserStore.getState();
  vanillaUserStore.setState({
    ...vanillaUserStore.getState(),
    user: {
      ...user,
      firstname: metadata.firstname,
      lastname: metadata.lastname
    }
  });
};

export const updateProfilePicture = async (url: string) => {
  try {
    const timestamp = Date.now();
    const { user } = vanillaUserStore.getState();
    vanillaUserStore.setState({
      ...vanillaUserStore.getState(),
      user: {
        ...user,
        picture: `${url}?t=${timestamp}`
      }
    });
  } catch (e) {
    console.log(e);
  }
};
