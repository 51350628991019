import React, { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Content from "../../components/layout/Content";
import "./styles.scss";
import OneSignal from "react-onesignal";
import RGPDModal from "../../components/modal/RGPDModal";
import ChangePassModal from "../../components/modal/ChangePassModal";
import { getCurrentLanguage, updateLanguage } from "../../utils/i18n";

const Settings = () => {
  const [t] = useTranslation();
  const [notifications, setNotifications] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showRGPDModal, setShowRGPDModal] = useState(false);

  useEffect(() => {
    getNotificationPermission();
  }, []);

  const getNotificationPermission = async () => {
    const perm = await OneSignal.isPushNotificationsEnabled();
    setNotifications(perm);
  };

  const changeLanguage = async (e: SyntheticEvent<HTMLSelectElement>) => {
    await updateLanguage(e.currentTarget.value);
  };

  return (
    <Content title={t("pages.settings.title")}>
      <ChangePassModal
        show={showChangePasswordModal}
        setShow={setShowChangePasswordModal}
      />
      <RGPDModal show={showRGPDModal} setShow={setShowRGPDModal} />
      <div
        className="inteach-settings__item"
        onClick={() => setShowChangePasswordModal(true)}>
        <p>{t("pages.settings.changePassword")}</p>
        <p>&gt;</p>
      </div>
      <div className="inteach-settings__item">
        <p>{t("pages.settings.changeLanguage")}</p>
        <select
          onChange={changeLanguage}
          value={getCurrentLanguage()}
          className="inteach-settings__lang-select">
          <option value="fr">{t("languages.fr")}</option>
          <option value="en">{t("languages.en")}</option>
          <option value="de">{t("languages.de")}</option>
          <option value="es">{t("languages.es")}</option>
          <option value="nl">{t("languages.nl")}</option>
          <option value="it">{t("languages.it")}</option>
        </select>
      </div>
      <div
        className="inteach-settings__item"
        onClick={() => setShowRGPDModal(true)}>
        <p>{t("pages.settings.infosRGPD")}</p>
        <p>&gt;</p>
      </div>
      <div
        className="inteach-settings__item"
        onClick={async () => {
          if (notifications) {
            await OneSignal.setSubscription(false);
            setNotifications(false);
          } else {
            // @ts-ignore
            await window.OneSignal.showNativePrompt(); // TODO Make a PR to react-onesignal
            await OneSignal.setSubscription(true);
            setNotifications(true);
          }
        }}>
        <p>{t("pages.settings.toggleNotifications")}</p>
        <p>{notifications ? t("common.yes") : t("common.no")}</p>
      </div>
    </Content>
  );
};

export default Settings;
