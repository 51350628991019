import React from "react";
import { setupIonicReact, isPlatform } from '@ionic/react';
import OneSignal from 'onesignal-cordova-plugin';
import { vanillaUserStore } from "./stores/users";

function OneSignalInit(id_user: string): void {
  // OneSignal.setLogLevel(6, 0);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  if (process.env.REACT_APP_ONESIGNAL_KEY) {
    OneSignal.setAppId(process.env.REACT_APP_ONESIGNAL_KEY);
    if (id_user) {
      OneSignal.setExternalUserId(id_user);
    }
    OneSignal.setNotificationOpenedHandler(function(jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });
  
    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log("User accepted notifications: " + accepted);
    });
  }
}

export default function ConfigMain() {
  setupIonicReact();
  
  if (isPlatform('capacitor')) {
    const { user } = vanillaUserStore.getState();
    OneSignalInit(user.id_user);
  }
  return (
    <div>
    </div>
  );
}