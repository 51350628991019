import React, { useEffect, useState } from "react";
import Modal from "./BaseModal";
import Slider from "react-slick";
import { getContentLang } from "../../utils/i18n";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";
import { acceptRGPD, updateUserV2, } from "../../utils/UserUtils";
import Config from "../../config";
import useUserStore, { userSelector } from "../../stores/users";


type FormInput = {
  firstname?: string;
  lastname?: string;
  ducrayClientRef?: string;
  ducrayPharmacyName?: string;
  city?: string;
  zipcode?: string;
}

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  arrows: false,
  draggable: false,
  className: "inteach-onboarding__slider",
  adaptiveHeight: true
};

const introConfig = Config.get("config.intro");

const animatedScreens = {
  step1: {
    source: require("../../resources/graduation_white.json"),
    colorFilters: [
      {
        keypath: "Shape Layer 1",
        color: "#FFFFFF"
      },
      {
        keypath: "Shape Layer 2",
        color: "#FFFFFF"
      },
      {
        keypath: "Shape Layer 3",
        color: "#FFFFFF"
      }
    ]
  },
  rgpd1: {
    source: require("../../resources/user_white.json"),
    colorFilters: [
      {
        keypath: "Warstwa 4",
        color: "#FFFFFF"
      },
      {
        keypath: "Warstwa 5",
        color: "#FFFFFF"
      },
      {
        keypath: "Warstwa 6",
        color: "#FFFFFF"
      },
      {
        keypath: "Warstwa 7",
        color: "#FFFFFF"
      }
    ]
  },
  rgpd2: {
    source: require("../../resources/document.json"),
    colorFilters: [
      {
        keypath: "Todolist_precomp",
        color: "#FFFFFF"
      },
      {
        keypath: "Warstwa 25",
        color: "#FFFFFF"
      },
      {
        keypath: "Warstwa 30",
        color: "#FFFFFF"
      },
      {
        keypath: "Warstwa 29",
        color: "#FFFFFF"
      },
      {
        keypath: "Warstwa 28",
        color: "#FFFFFF"
      },
      {
        keypath: "Warstwa 2",
        color: "#FFFFFF"
      }
    ]
  },
  rgpd3: {
    source: require("../../resources/brain_white.json"),
    colorFilters: [
      {
        keypath: "Chat",
        color: "#FFFFFF"
      }
    ]
  }
};

const OnBoarding = ({
  isOpen,
  setIsOpen,
  onRequestClose = true
}: {
  isOpen: boolean;
  setIsOpen: any;
  onRequestClose?: boolean;
}) => {
  const [t] = useTranslation();
  const [step, setStep] = useState(1);
  const [reactSlider, setReactSlider] = useState();
  const [input, setInput] = useState<FormInput>({});
  const user = useUserStore(userSelector("user"));

  if (!user) return null;
  let fields: Array<any> = [];
  // @ts-ignore
  if (user.organization_name === 'Ducray') {
    fields = ["firstname", "lastname", "ducrayClientRef", "ducrayPharmacyName", "zipcode", "city"]
  } else {
    fields = ["firstname", "lastname"];
  }

  const fieldsFilled = () => {
    for (const field of fields) {
      // @ts-ignore
      if (!input[field]) {
        return false;
      }
    }
    return true
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose ? () => setIsOpen(false) : () => {}}
      size="lg"
      closeBtn={false}
      headerAbsolute
      contentStyle={{ padding: 0 }}>
      <Slider
        {...settings}
        ref={slider => {
          // @ts-ignore
          if (slider) setReactSlider(slider);
        }}
        afterChange={async index => {
          setStep(index);
          if (index) {
            await updateUserV2(input);
          }
        }}>
        {Object.entries(introConfig).map((o: any, index: number) => {
          const [step, { backgroundColor, textColor, title, text }] = o;
          // @ts-ignore
          const { source } = animatedScreens[step];
          return (
            <div key={index} className="h-100">
              <div
                className="inteach-onboarding__slide"
                style={{
                  backgroundColor,
                  color: textColor
                }}>
                <div style={{ marginBottom: "1.5rem" }}>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: source
                    }}
                    width={100}
                    height={100}
                  />
                </div>
                <h3>{getContentLang(title)}</h3>
                <p>{getContentLang(text)}</p>
                {step === "step1" &&
                  fields.map(field => (
                    <input
                      key={field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setInput({ ...input, [field]: e.target.value })
                      }
                      id={field}
                      className="inteach-input inteach-input--round mt-2"
                      placeholder={t("user." + field)}
                    />
                  ))}
              </div>
            </div>
          );
        })}
      </Slider>
      {fieldsFilled() && (
        <a
          onClick={async () => {
            // @ts-ignore
            reactSlider.slickNext();
            if (step === Object.keys(introConfig).length - 1) {
              await acceptRGPD();
              window.location.replace("/auth");
              setIsOpen(false);
            }
          }}
          className="inteach-onboarding__btn">
          {step === Object.keys(introConfig).length - 1
            ? t("common.start")
            : t("common.next")}
        </a>
      )}
    </Modal>
  );
};

export default OnBoarding;
