import { Auth } from "aws-amplify";
import axios from "axios";
import Config from "../config";

const anonymousURL = [
  "s3.eu-west-3.amazonaws.com",
  "getAwsConfig",
  "links/identifier",
  ""
];


interface API {
  get: (endpoint: string, params?: any) => any;
  post: (endpoint: string, data?: object, params?: object, config?: any) => any;
  put: (endpoint: string, data?: object, params?: object, config?: any) => any;
  delete: (endpoint: string) => any;
}

export const API_VERSION = 3;

class BaseAPI {
  private readonly _http: any;

  constructor() {
    this._http = axios.create({
      baseURL: Config.get("config.apiURL") || Config.get("api.path"),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.inteachacademyapi.v2+json",
        "api-version": API_VERSION
      }
    });

    this._http.interceptors.request.use(
      async (config: any) => {
        // Root config for S-Administrator
        const loginAs = localStorage.getItem("loginAs");

        if (loginAs) {
          config.headers["X-ADMINISTRATOR-LOGIN-AS"] = loginAs;
        }

        if (anonymousURL.find(u => new RegExp(u).test(config.url)))
          return config;

        const data = await Auth.currentSession();
        config.headers.Authorization = `Bearer ${data
          .getAccessToken()
          .getJwtToken()}`;
        return config;
      },
      (error: any) => {
        throw error;
      }
    );

    this._http.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        if (error?.response) {
          const status = error.response.status;
          if (status === 401 || status === 403) {
            throw new Error("NOT_AUTHORIZED");
          }
        }

        throw error;
      }
    );
  }

  async get(endpoint: string, params: any) {
    const { data } = await this._http.get(endpoint, { params });
    return data;
  }

  async post(endpoint: string, data = {}, { params = {}, ...config } = {}) {
    const { data: responseData } = await this._http.post(endpoint, data, {
      params,
      ...config
    });
    return responseData;
  }

  async put(endpoint: string, data = {}, { params = {}, ...config } = {}) {
    const { data: responseData } = await this._http.put(endpoint, data, {
      params,
      ...config
    });
    return responseData;
  }
  async delete(endpoint: string) {
    return await this._http.delete(endpoint);
  }
}

const instance: API = new BaseAPI();

export default instance;
