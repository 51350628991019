import React, { useEffect, useState, useCallback } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { useParams, useHistory } from "react-router-dom";

import Content from "../../components/layout/Content";
import * as TrainingAPI from "../../api/trainings";
import LearningSet from "../../components/trainings/LearningSet";
import { ITraining } from "../../../types/training";
import { IAction } from "../../../types/action";
import Card from "../../components/Card";
import TrainingsInfo from "../../components/trainings/TrainingsInfo";
import ProgressCircle from "../../components/ProgressCircle";
import { getContentLang } from "../../utils/i18n";

import useTrainingsStore from "../../stores/trainings";

const MAX_OPACITY = 200;

const Training = () => {
  //@ts-ignore
  const { id_training } = useParams();
  const [actions, setActions] = useState<IAction[]>([]);
  const [userProgress, setUserProgress] = useState<ITraining>();
  const [X, setX] = useState<number>(0);
  const [opacity, setOpacity] = useState<number>(1);

  const history = useHistory();

  const addLearningItems = useTrainingsStore(
    useCallback(state => state.addLearningItems, [])
  );

  const updateTraining = useTrainingsStore(
    useCallback(state => state.updateTraining, [])
  );

  const training = useTrainingsStore(
    useCallback(
      state => state.trainings.find(t => t.id_training === id_training),
      []
    )
  );

  const { progress: currentProgress } = training || { progress: 0 };

  useEffect(() => {
    const getTraining = async () => {
      try {
        const _training = await TrainingAPI.get(id_training);
        updateTraining({
          ..._training,
          //@ts-ignore
          learnerCount: history.location.state?.learnerCount
        });
        addLearningItems(id_training, _training.actions);

        setActions(_training.actions);
      } catch (e) {}
    };

    getTraining();
  }, [history.location.state, id_training]);

  const renderLearningSet = (
    action: IAction,
    idTraining: string,
    index: number,
    defaultLang: string | undefined
  ) => {
    return (
      <LearningSet
        key={idTraining + index}
        items={action.items}
        title={action.title}
        idTraining={idTraining}
        userProgress={userProgress}
        defaultLang={defaultLang}
      />
    );
  };

  const onScroll = useCallback(e => {
    const element = e.target;

    setX(element.scrollTop);
    setOpacity(1 - element.scrollTop / MAX_OPACITY);
  }, []);

  return (
    <Content onScroll={onScroll} contentStyle={{ padding: 0 }}>
      {training ? (
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div className="training-single">
            <Card className="p-0 training-single__card">
              <div className="training-single__image">
                {training.picture && (
                  <img
                    src={training.picture}
                    alt={getContentLang(training.name, training.default_lang)}
                  />
                )}
              </div>
              <div
                className="training-single__info-container"
                style={{
                  transform: `translateY(calc(-50% - ${X}px))`,
                  opacity
                }}>
                <h1 className="training-single__title pt-1 pl-1 pb-1  border-radius border-radius--sm">
                  {getContentLang(training.name, training.default_lang)}
                </h1>

                <div className="d-flex align-items-center pl-1 pr-1 pb-1">
                  <TrainingsInfo
                    className="training-item__info--xl"
                    training={training}
                  />
                  <div className="ml-auto">
                    <ProgressCircle
                      progress={currentProgress || training.progress || 0}
                      size={"80px"}
                    />
                  </div>
                </div>
              </div>
            </Card>
            <div
              className="training-single__actions"
              style={{ marginTop: 80 - X }}>
              {actions.map((action, index) => {
                return renderLearningSet(
                  action,
                  training.id_training,
                  index,
                  training.default_lang
                );
              })}
            </div>
          </div>
        </ReactCSSTransitionGroup>
      ) : null}
    </Content>
  );
};

export default Training;
