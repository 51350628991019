import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";

const Link = ({ setAuthState }: { setAuthState: any }) => {
  // @ts-ignore
  const { identifier } = useParams();

  useEffect(() => {
    setAuthState("signUp");
  }, [setAuthState]);

  if (!identifier) return null;

  return <Redirect to={"/auth?identifier=" + identifier} />;
};

export default Link;
