import React, { useEffect, useState } from "react";
import DrawerButton from "../Drawer/DrawerButton";
import "./styles.scss";
import Input from "../../Input";
import { useHistory, useLocation } from "react-router-dom";
import SvgIcon from "../../SvgIcon";
import Notifications from "../../Notifications";
import { useTranslation } from "react-i18next";
import Config from "../../../config";
import { vanillaUserStore } from "../../../stores/users";

const titles = [
  {
    path: "/",
    title: "pages.wall.title"
  },
  {
    path: "/trainings",
    title: "pages.trainings.title"
  },
  {
    path: "/messaging",
    title: "pages.messaging.title"
  },
  {
    path: "/search",
    title: "pages.search.title"
  }
];

const Header = React.memo(
  ({ setIsOpen, isOpen }: { setIsOpen: any, isOpen: boolean }) => {
    const { user } = vanillaUserStore.getState();
    const [t] = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [keywords, setKeywords] = useState("");
    const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    useEffect(() => {
      setTitle(
        titles.find(title => title.path === location.pathname)?.title || ""
      );
    }, [location]);
    const finalTitle = title === 'pages.wall.title' ? t('common.hello') + ' ' + user.firstname : title;
    return (
      <div className="inteach-header">
        <Notifications
          isOpen={notificationsIsOpen}
          onClose={() => setNotificationsIsOpen(false)}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "100%"
          }}>
          <DrawerButton onClick={() => setIsOpen(!isOpen)} />
          <div className="inteach-header__logo">
            <img src={`/logo.png`} alt={Config.get("config.brandName")} />
          </div>
          <div style={{ display: "flex" }}>
            <button
              className="inteach-header__notification-btn"
              onClick={() => setNotificationsIsOpen(true)}>
              <SvgIcon name="notification" color="white" />
            </button>
            <form
              className="inteach-header__search"
              onSubmit={e => {
                e.preventDefault();
                history.push("/search?q=" + keywords);
                setKeywords("");
              }}>
              <Input
                // @ts-ignore
                type="text"
                className="inteach-header__search__input"
                placeholder={t("pages.search.searchPlaceholder")}
                iconRight="search2"
                value={keywords}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setKeywords(e.target.value)
                }
              />
            </form>
          </div>
        </div>
        <div className="inteach-header__title">
          <h1>{t(finalTitle)}</h1>
        </div>
      </div>
    );
  }
);

export default Header;
