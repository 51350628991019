import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { createBrowserHistory } from "history";
import { StreamChat } from "stream-chat";
import { Router } from "react-router-dom";
import { Amplify, I18n, Auth } from "aws-amplify";
import { ToastContainer } from "react-toastify";
import OneSignalWeb from "react-onesignal";
import { merge } from "lodash";
import ConfigMain from './ConfigMain';
import AppMain from "./AppMain";
import * as serviceWorker from "./serviceWorker";
import "./assets/base.scss";
import awsConfig from "./config/awsConfig";
import "./utils/i18n";
import { initLanguage } from "./utils/i18n";
import { initUser } from "./utils/UserUtils";
import Config from "./config";
import fr from "./config/langs/fr";
import en from "./config/langs/en";
import es from "./config/langs/es";
import nl from "./config/langs/nl";
import de from "./config/langs/de";
import { isPlatform } from '@ionic/react';
import { SplashScreen } from '@capacitor/splash-screen';


const chatClient: StreamChat = new StreamChat("", {
  timeout: 10000
});
export const history = createBrowserHistory();

I18n.putVocabularies({
  fr: fr.amplify,
  en: en.amplify,
  es: es.amplify,
  nl: nl.amplify,
  de: de.amplify
});

const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

(async () => {
  Config.init();
  const history = createBrowserHistory();
  try {
    await initLanguage();
    const { cloudfront, name, ...authConfig } = await awsConfig();
    const localAuthConfig = Config.get("config.amplify.Auth", {});
    const AmplifyAuthConfig = merge({}, authConfig, localAuthConfig);
    Amplify.configure({
      ...AmplifyAuthConfig,
      Analytics: { disabled: true }
    });

    Auth.configure(AmplifyAuthConfig);
    // We don't want OneSignal Web notifications in android & ios apps
    if (!isPlatform('capacitor')) {
      OneSignalWeb.initialize(Config.get("config.oneSignal.appId"), {
        safari_web_id: Config.get("config.oneSignal.safari_web_id"),
        allowLocalhostAsSecureOrigin: false,
        autoResubscribe: true,
        notifyButton: {
          enable: true /* Required to use the Subscription Bell */,
          /* SUBSCRIPTION BELL CUSTOMIZATIONS START HERE */
          size: "small" /* One of 'small', 'medium', or 'large' */,
          theme:
            "inverse" /* One of 'default' (red-white) or 'inverse" (white-red) */,
          position: "bottom-right" /* Either 'bottom-left' or 'bottom-right' */,
          prenotify: false /* Show an icon with 1 unread message for first-time site visitors */,
          showCredit: false /* Hide the OneSignal logo */,
          colors: {
            // Customize the colors of the main button and dialog popup button
            "circle.background": "var(--main-color)",
            "circle.foreground": "white",
            "badge.background": "var(--main-color)",
            "badge.foreground": "white",
            "badge.bordercolor": "white",
            "pulse.color": "white",
            "dialog.button.background.hovering": "var(--main-color))",
            "dialog.button.background.active": "var(--main-color)",
            "dialog.button.background": "var(--main-color)",
            "dialog.button.foreground": "white"
          },
          text: i18n.t("oneSignal.bellPrompt", { returnObjects: true })
        }
      });
    }
  } catch (e) {}

  await initUser();

  ReactDOM.render(
    <React.StrictMode>
      <ConfigMain></ConfigMain>
      <Router history={history}>
        <AppMain chatClient={chatClient} />
        <ToastContainer position="bottom-center" hideProgressBar />
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  );
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
  if (isPlatform('capacitor')) {
    setTimeout(() => {
      SplashScreen.hide();
    }, 2000);
  }
})();
