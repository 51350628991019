import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { default as LoaderSpinner } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

import * as TrainingsAPI from "../../api/trainings";
import TrainingsItem from "./TrainingsItem";
import { ITraining } from "../../../types/training";
import useTrainingsStore from "../../stores/trainings";

const RESULT_PER_PAGE = 10;

const TrainingsList = ({
  onItemClick,
  all = false
}: {
  onItemClick?: (training: ITraining) => void;
  all?: boolean;
}) => {
  const [t] = useTranslation();
  const [trainings, setTrainings] = useState<ITraining[]>([]);
  const [initialFetch, setInitialFetch] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [seeMore, setSeeMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const addTrainings = useTrainingsStore(state => state.addTrainings);

  const loadMore = useCallback(() => {
    setCurrentPage(cp => cp + 1);
  }, []);

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        setIsFetching(true);
        const _trainings: ITraining[] = await TrainingsAPI.list(
          currentPage * RESULT_PER_PAGE,
          RESULT_PER_PAGE,
          true
        );
        const _usedTrainings = all
          ? _trainings
          : _trainings.filter(({ started }) => started);

        if (!_usedTrainings.length) setSeeMore(false);

        setIsFetching(false);
        addTrainings(_usedTrainings, { append: currentPage > 0 });
        setTrainings(t => [...t, ..._usedTrainings]);

        setInitialFetch(false);
      } catch (e) {}
    };

    fetchTrainings();
  }, [currentPage]);

  return (
    <>
      {initialFetch ? (
        <div className="d-flex align-items-center justify-content-center">
          <LoaderSpinner
            type="BallTriangle"
            color="var(--main-color)"
            height={50}
            width={50}
          />
        </div>
      ) : null}
      {trainings.map((training: ITraining) => (
        <ReactCSSTransitionGroup
          key={training.id_training}
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <TrainingsItem
            training={training}
            onClick={onItemClick ? () => onItemClick(training) : undefined}
            key={training.id_training}
          />
        </ReactCSSTransitionGroup>
      ))}
      {initialFetch || !seeMore ? (
        <div style={{ height: "15px" }}></div> // virtually add a margin bottom if no button
      ) : (
        <div className="mb-1 mt-1 d-flex align-items-center justify-content-center">
          <button
            onClick={loadMore}
            className="inteach-button inteach-button--primary">
            {isFetching ? t("common.loading") : t("common.seeMore")}
          </button>
        </div>
      )}
    </>
  );
};

export default TrainingsList;
