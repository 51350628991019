import React, { CSSProperties } from "react";
// @ts-ignore
import { Card, Video, Audio } from "react-activity-feed";

import { getContentLang } from "../../utils/i18n";

const PostContent = ({
  containerStyle = {},
  textStyle = {},
  activity,
  training,
  showTag = false
}: {
  containerStyle?: CSSProperties;
  textStyle?: object;
  activity: any;
  training: any;
  showTag: boolean;
}) => {
  const { attachments = {} } = activity;

  const _renderTrainingName = () => {
    if (!training) return null;
    const tagName = getContentLang(training.name, training.default_lang);
    if (!tagName) return null;
    return <span className="post__tag">{tagName}</span>;
  };

  const imgSrc = activity.attachments?.images[0];

  return (
    <div className="post__content pl-1 pr-1 mt-1 mb-1" style={containerStyle}>
      <p>{activity?.text || activity?.object}</p>
      {showTag && _renderTrainingName()}
      {imgSrc && (
        <div className="post__img mt-1">
          <img src={imgSrc} alt="" />
        </div>
      )}
      {attachments &&
        attachments.og &&
        Object.keys(attachments.og).length > 0 && (
          <div style={{ padding: "8px 16px" }}>
            {attachments.og.videos ? (
              <Video og={attachments.og} />
            ) : attachments.og.audios ? (
              <Audio og={attachments.og} />
            ) : (
              <Card {...attachments.og} />
            )}
          </div>
        )}
    </div>
  );
};

export default PostContent;
