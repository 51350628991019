import React from "react";
import { NavLink } from "react-router-dom";
import SvgIcon from "../../SvgIcon";
import "./styles.scss";
import Config from "../../../config";

import { TbMessageCircle, TbSearch, TbSchool, TbHome } from "react-icons/tb"
;
const { messages } = Config.get("config.screens");

const Tabbar = () => {
  return (
    <div className="inteach-tabbar">
      <nav>
        <ul>
          <li>
            <NavLink exact to="/">
              <TbHome />
            </NavLink>
          </li>
          <li>
            <NavLink to="/trainings">
              <TbSchool />
            </NavLink>
          </li>
          <li>
            <NavLink to="/search">
              <TbSearch />
            </NavLink>
          </li>
          {messages && (
            <li>
              <NavLink to="/messaging">
                <TbMessageCircle />
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Tabbar;
