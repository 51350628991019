import _ from "lodash";
import productionConfig from "./production";
import developmentConfig from "./development";
import currentOrganization from "../currentOrga/config.json";
import { camelCaseToKebabCase } from "../utils";
import colors from "../theme/colors";

const apiConfig =
  process.env.NODE_ENV === "development" ? developmentConfig : productionConfig;
// const apiConfig = productionConfig;
const Config = {
  init: () => {
    const rootEl = document.querySelector(":root");

    if (!rootEl) return;

    Object.entries(Config.get("theme")).forEach(([key]) => {
      const value = Config.getTheme(key);
      //@ts-ignore
      rootEl.style.setProperty(
        "--" + camelCaseToKebabCase(key),
        Array.isArray(value) ? value[0] : value
      );
    });
  },
  get: (path: string, defaultValue?: any): any => {
    return _.get({ ...currentOrganization, ...apiConfig }, path, defaultValue);
  },

  getTheme: (path: string): string => {
    const theme = _.get(
      { ...currentOrganization, ...apiConfig },
      "theme." + path
    );

    if (theme !== "default") return theme;

    //@ts-ignore
    return colors[path];
  }
};

export default Config;
