import React, { useState, useEffect } from "react";
import { IonButton, IonIcon } from '@ionic/react';
import "./styles.scss";
import { logOut } from 'ionicons/icons';
import "../Leftbar/styles.scss";
import { NavLink } from "react-router-dom";
import SvgIcon from "../../SvgIcon";
import { useTranslation } from "react-i18next";
import { Auth, I18n, PubSub } from "aws-amplify";
import { toast } from "react-toastify";
import ProfilePicture from "../../ProfilePicture";
import useUserStore, { userSelector } from "../../../stores/users";
import { IUser } from "../../../../types/users";

const Drawer = ({ isOpen = false }: { isOpen: boolean }) => {
  const [t] = useTranslation();
  const [authenticatedUser, setAuthenticatedUser] = useState();
  const [userPoints, setUserPoints] = useState(null);
  //@ts-ignore
  const user: IUser = useUserStore(userSelector("user"));

  useEffect(() => {
    if (!authenticatedUser) return () => {};

    const subToTopic = () => {
      return PubSub.subscribe(`analytics/${authenticatedUser}/get`).subscribe({
        next: ({ value }) => {
          const { points } = value;

          setUserPoints(points);
        },
        error: error => {
          console.log("error", error);
        }
      });
    };

    const subscription = subToTopic();

    return () => subscription.unsubscribe();
  }, [authenticatedUser, setUserPoints]);

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      const {
        attributes: { sub }
      } = await Auth.currentAuthenticatedUser();
      setAuthenticatedUser(sub);
    };
    getAuthenticatedUser();
  }, []);

  if (!user) return null;

  const onLogout = async () => {
    try {
      localStorage.removeItem("loginAs");
      await Auth.signOut();
    } catch (e) {
      toast.error(I18n.get("errorSignOut"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    }
  };

  return (
    <div className={`inteach-drawer ${isOpen ? "inteach-drawer__open" : ""}`}>
      <div className="inteach-drawer__content">
        <div style={{ flex: 1 }}>
          <ProfilePicture size={60} canEdit />
          <h3 className="inteach-leftbar__title">
            {user.firstname} {user.lastname}
          </h3>
          <span className="inteach-leftbar__points">
            {userPoints !== null ? userPoints : user.points}
            <SvgIcon name="star" height={25} width={25} />
          </span>
        </div>
        <div style={{ flex: 1 }}>
          <nav className="inteach-leftbar__nav">
            <ul>
              <li className="inteach-leftbar__link">
                <NavLink exact to="/">
                  <SvgIcon name="home" />
                  {t("menu.home")}
                </NavLink>
              </li>
              <li className="inteach-leftbar__link">
                <NavLink to="/account">
                  <SvgIcon name="user" /> {t("menu.account")}
                </NavLink>
              </li>
              <li className="inteach-leftbar__link">
                <NavLink to="/settings">
                  <SvgIcon name="settings" /> {t("menu.settings")}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            display: "flex",
            justifyContent: "flex-end"
          }}>
          <IonButton fill="outline" onClick={onLogout}>
            <IonIcon slot="start" icon={logOut}></IonIcon>
              {t("common.signout")}
          </IonButton>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
