export default {
  common: {
    cancel: "Annulla",
    update: "Aggiorna",
    understood: "Ho capito",
    next: "Seguenti",
    agree: "Accetta",
    signout: "Disconnetti",
    start: "Inizio",
    save: "Salva",
    settingUpWebapp: "Prepariamo la tua webapp",
    contactUs: "Contattaci",
    loading: "Caricamento",
    seeMore: "Vedi altro",
    loadMore: "Carica altro",
    yes: "Sì",
    no: "No",
    open: "Aperto",
    close: "Chiudi",
    french: "Francese",
    english: "Inglese",
    spanish: "Spagnolo",
    italian: "Italiano",
    german: "Tedesco",
    dutch: "Olandese",
    rejoin: "Unisciti"
  },
  languages: {
    fr: "Francese",
    en: "Inglese",
    es: "Spagnolo",
    nl: "Olandese",
    de: "Tedesco",
    it: "Italiano"
  },
  menu: {
    home: "Home",
    trainings: "Il tuo allenamento",
    account: "Il mio account",
    settings: "Impostazioni",
    wall: "Il mio Wall"
  },
  user: {
    updateProfilePictureSuccess: "Immagine del profilo aggiornata!",
    errorUploadProfilePicture:
      "L'immagine del profilo non può essere aggiornata.",
    updateProfileSuccess: "Il tuo profilo è stato aggiornato!",
    errorUpdateProfile: "Il tuo profilo non può essere aggiornato.",
    updatePasswordSuccess: "La tua password è stata aggiornata!",
    errorUpdatePassword: "Impossibile aggiornare la password.",
    firstname: "Nome",
    lastname: "Nome"
  },
  amplify: {
    "Code hint":
      "Verifica che il tuo codice di 4 caratteri corrisponda agli ultimi 4 caratteri del link che hai ricevuto.",
    signupEmail: "Inserisci la tua email",
    signupPassword: "Inserisci la tua password (minimo 8 caratteri)",
    "Reset your password": "Reimposta la password",
    "Send Code": "Invia il codice",
    "Code Sent": "Codice inviato",
    "Create Account": "Iscriviti",
    successSignIn: "Connessione riuscita!",
    successSignOut: "Sei disconnesso",
    errorSignOut: "Impossibile effettuare il logout",
    emptyPassword: "La password deve essere compilata",
    "Resend code": "Restituisci il codice",
    "Sign in to your account": "Connessione",
    "Sign in": "Connessione",
    InvalidEmail: "Formato email non valido",
    inscriptionCode: "Codice di registrazione",
    Email: "Indirizzo email",
    "Create a new account": "Crea un account",
    "Enter your username": "Nome utente",
    "Enter your email": "Email",
    "Enter your password": "Password",
    "Temporary password has expired and must be reset by an administrator.":
      "La password temporanea è scaduta e deve essere reimpostata da un amministratore",
    "Use QRCode": "Usa un codice QR",
    Submit: "Invia",
    Send: "Invia",
    "Enter your confirmation code": "Codice di conferma ricevuto via e-mail.",
    "Enter your new password": "Nuova password",
    "Incorrect username or password.": "Password errata",
    "User does not exist.": "Password errata",
    InvalidParameterExceptionEmail: "Formato email non valido",
    UsernameExistsException: "Questo indirizzo email esiste già.",
    InvalidPasswordExceptionUppercase:
      "La password deve avere lettere maiuscole",
    InvalidPasswordExceptionLowercase:
      "La password deve contenere lettere minuscole",
    InvalidPasswordExceptionNumeric: "La password deve contenere numeri",
    InvalidPasswordExceptionLong:
      "La password deve contenere almeno 8 caratteri",
    InvalidPasswordExceptionSymbol:
      "La password deve contenere caratteri speciali",
    InvalidParameterExceptionGreaterthanorequal:
      "La password deve contenere almeno 8 caratteri",
    InvalidParameterExceptionPasswordempty: "La password è obbligatoria",
    UserNotFoundException: "Utente non trovato",
    NotAuthorizedExceptionIncorrectusername: "Utente non trovato",
    NotAuthorizedException: "E-mail o password errate",
    InvalidPasswordExceptionNotLongEnough:
      "La password deve essere composta da almeno 8 caratteri",
    InvalidPasswordExceptionBadFormat:
      "Formato password non valido (non deve iniziare o terminare con uno spazio)",
    InvalidParameterException: "Si è verificato un errore",
    LimitExceededException:
      "Il limite di tentativi è stato superato, riprova più tardi.",
    InvalidParameterExceptionThereisnoregisteredverifiedemail:
      "Non puoi cambiare la password perché il tuo account non è verificato. Contatta il tuo amministratore.",
    InvalidParameterExceptionAlreadyconfirmed: "L'utente è già confermato.",
    "I've got a code": "Registrati con un codice",
    "Forget your password?": "Hai dimenticato la password",
    inscriptionCodeHint: "Codice di registrazione a 4 caratteri",
    ssoButtonTitleDoctolib: "Accedi con Doctolib",
    "Back doctolib login": "Ritorna alla connessione Doctolib",
    Redirection: "Verrai reindirizzato al tuo fornitore di servizi",
    CodeMismatchException: "Codice di verifica non valido",
    connectionLoading: "Connessione",
    signUpLoading: "Registrazione in corso",
    sendingCode: "Invio del codice",
    changingPassword: "Modifica della password"
  },
  rightbar: {
    messaging: "Messaggistica",
    statistics: "Statistiche",
    trainers: "Le tue scarpe da ginnastica",
    formationTime: "Tempo di allenamento",
    sessionNumber: "Numero di sessioni",
    mainTrainer: "Allenatore senior"
  },
  messaging: {
    title: "Chat",
    chat: "Chat",
    yourTrainers: "Le tue scarpe da ginnastica",
    mainTrainer: "Allenatore senior",
    nothingYet: "Inizia la conversazione",
    loadingChannels: "Caricamento messaggi...",
    writeMessage: "Messaggio",
    sendMessage: "Invia",
    Commands: "Ordini",
    searchingPeople: "Cerca utenti",
    uploadFile: "Aggiungi un file",
    uploadPhoto: "Aggiungi una foto",
    addFile: "Aggiungi un file",
    cancel: "Annulla",
    chooseAction: "Scegli un'azione",
    deleteMessage: "Cancella",
    editMessage: "Modifica",
    reply: "Risposta",
    addReaction: "Reagire",
    oneReply: "1 risposta",
    countReplies: "{{replyCount}} risposte",
    loadingMessages: "Caricamento messaggi...",
    editingMessage: "Modifica del messaggio",
    startThread: "Inizia una conversazione",
    startNewThread: "Inizia una nuova conversazione",
    noChannelsYet: "Non hai ancora un chat",
    noItems: "Nessun elemento",
    messageDeleted: "Messaggio eliminato",
    sent: "Inviato",
    openEmojiPicker: "Apri il selettore emoji",
    close: "Chiudi",
    countOnline: "{{watcherCount}} online",
    limitExceeded: "Riprova più tardi..."
  },
  api: {
    unknownError: "Si è verificato un errore. Riprova",
    errorUploadProfilePicture: "Impossibile caricare la foto del profilo"
  },
  start: "Inizia",
  continue: "Continua",
  signout: "Esci",
  "Network Error": "Controlla lo stato della tua connessione internet",
  codePushUpdateAvailable: "Aggiornamento disponibile",
  codePushUpdateAvailableInstall:
    "Se è disponibile una nuova versione, vuoi installarla ora?",
  links: {
    usingLinkInProgress: "Utilizzando il link attuale...",
    linkUsed: "Link utilizzato!",
    linkNotAuthorized: "Non è consentito utilizzare questo link",
    linkInternalError: "Non siamo stati in grado di applicare il link"
  },
  modals: {
    notifications: {
      title: "Notifica",
      empty: "Nessuna notifica"
    },
    editAccount: {
      title: "Modifica il mio account"
    },
    rgpd: {
      title: "GDPR"
    },
    changePassword: {
      title: "Cambia la password",
      oldPasswordLabel: "Vecchia password",
      newPasswordLabel: "Nuova password",
      newPasswordConfirmLabel: "Conferma password"
    },
    capsuleReward: {
      points_success_activity_interval:
        "Hai completato questa attività e guadagnato (0) {0 punti}; (1) {1 punto};",
      points_success_activity_plural:
        "Hai completato questa attività e hai guadagnato {{count}} punti",
      percent_correct: "{{count}}% risposte corrette",
      minimum_required: "Minimo richiesto: {{count}}%",
      congrats: "Congratulazioni!",
      fail: "Non riuscito",
      reward: "Hai completato questa attività e hai vinto ",
      failDescription:
        "Questa volta non ci sei riuscito. Riprova a convalidare questa capsula!"
    }
  },
  pages: {
    help: {
      upgrade: "Aggiorna",
      appUpgradeNeededTitle:
        "Questa applicazione è a immagine dei suoi studenti, sta progredendo! 🎉",
      appUpgradeNeeded:
        "Per una migliore esperienza di apprendimento, ti invitiamo ad aggiornare questa app.",
      goBackTrainings: "Torna alla formazione",
      linkChar:
        "Inserisci gli ultimi quattro caratteri del link di invito nel campo sottostante se non sei riuscito ad accedere alla schermata di registrazione. (Ad esempio: «abcd» per il seguente link https://{{brand}}.page.link/abcd).",
      emptyCode: "Il campo non può essere vuoto.",
      codeNotFound: "Non siamo riusciti a trovare il link di registrazione.",
      verifyLink: "Verifica",
      titleNotConfirmed: "Il tuo account non è confermato.",
      title: "Non riusciamo a trovare il tuo account 🕵️‍♀️",
      signupCodeTitle: "Codice di registrazione errato",
      signupCodeText:
        "Verifica che il codice a 4 cifre che hai inserito sia corretto. In questo caso, contattaci cliccando sul pulsante qui sotto!",
      titleExists: "Questo account esiste già 🔍",
      noPanic: "Non fatevi prendere dal panico 🧘🏽‍♂",
      alreadySignup:
        "Se non ti sei mai registrato con questa email, contatta l'amministratore in modo che possa ricreare un account per te.",
      alreadySignupConfirmCode:
        "Se il tuo account non è confermato e desideri farlo, fai clic su «Conferma un codice» e poi su «Invia nuovamente un codice».",
      didSignupError:
        "Se hai riscontrato un problema durante la registrazione puoi contattarci cliccando sul pulsante qui sotto.",
      tryLoginAgain: "Prova di nuovo ad accedere",
      codeSent: "Ti abbiamo appena inviato un codice",
      emailSubject: "{{brand}} problema di registrazione",
      emailBody:
        "\nCiao,\n\nNon riesco ad accedere con la mia email: {{username}}.\n\nMi puoi aiutare?\n\nGrazie\n      "
    },
    messaging: {
      title: "Messaggistica"
    },
    account: {
      title: "Il mio account",
      editButton: "Modifica le mie informazioni",
      trainingTime: "Tempo di allenamento",
      points: "Punti esperienza",
      sessionNumber: "Numero di sessioni questa settimana",
      time_per_user_format_hours: "{{ore}} h {{minuti}}",
      time_per_user_format_minutes: "{{minuti}} min",
      statsLoadError:
        "Non siamo riusciti a caricare le tue statistiche, prova a tornare più tardi"
    },
    settings: {
      title: "Impostazioni",
      changePassword: "Cambia la password",
      changeLanguage: "Cambia lingua",
      infosRGPD: "Informazioni sul GDPR",
      toggleNotifications: "Notifica"
    },
    trainings: {
      title: "Il tuo allenamento",
      refreshListFail: "Impossibile caricare l'allenamento",
      noTraining: "Nessun allenamento nelle scatole!",
      participants_interval:
        "(0) {Non c'è nessun partecipante}; (1) {1 partecipante};",
      participants_plural: "{{count}} partecipanti",
      points_interval: "(0) {0 punto}; (1) {1 punto};",
      points_plural: "{{count}} punti",
      training_not_started: "Questa formazione sarà disponibile il {{date}}"
    },
    wall: {
      title: "Novità",
      loadingFail: "Al momento non siamo in grado di caricare i tuoi post.",
      commentsCount: "Commento {{count}}",
      commentsCount_plural: "Commenti di {{count}}",
      likes: "{{count}} Mi piace",
      otherLikes: "{{name}} e {{count}} altre persone sono piaciute",
      otherLikes_plural: "{{name}} e {{count}} altri sono piaciuti",
      otherLikesPeople_1: "{{firstUser}} mi piace",
      otherLikesPeople_2: "{{firstUser}} e {{secondUser}} sono piaciuti",
      otherLikesPeople_3:
        "{{firstUser}}, {{secondUser}} e {{thirdUser}} sono piaciuti",
      commentBoxPlaceholder: "Il tuo commento",
      seeLikes: "Vedi",
      loadMoreComments: "Carica altri commenti",
      loadMoreLikes: "Carica altri Mi piace",
      newPosts_interval: "(1) {1 nuovo post};",
      newPosts_plural: "{{count}} nuovi post",
      likesModal: {
        title: "Mi piace ({{count}})"
      },
      firstToComment: "Puoi essere il primo a commentare!",
      start: "Inizio",
      noPosts: "Ancora nessun post nella tua bacheca",
      loadMorePosts: "Carica altri post"
    },
    search: {
      title: "Ricerca",
      refreshListFail: "La ricerca non è riuscita",
      noResult: "Nessun risultato trovato",
      searchPlaceholder: "Cerca contenuti",
      filters: {
        keywords: "Ricerca per parole chiave",
        training: "Filtra per allenamento",
        types: {
          capsules: "Berretti",
          videos: "Video",
          files: "File"
        }
      }
    }
  },
  learningSets: {
    learningItems: {
      locked: "Completa l'attività precedente",
      availableOn: "Disponibile {{date}}",
      capsule: {
        title: "Capsula"
      },
      test: {
        title: "Valutazione"
      },
      video: {
        title: "Video"
      },
      scorm: {
        title: "Pacchetto SCORM"
      },
      file: {
        title: "File"
      },
      practice: {
        title: "Mettere in pratica",
        moreTime: "Ho ancora bisogno di un po' di tempo",
        done: "Ho finito!",
        confirm: "Confermo",
        alertTitle: "Sei sicuro?",
        alertText:
          "Confermo di aver completato questa pratica. Se un rendering è richiesto dal mio allenatore, l'ho inviato"
      }
    }
  },
  oneSignal: {
    bellPrompt: {
      "tip.state.unsubscribed": "Iscriviti alle notifiche",
      "tip.state.subscribed": "Sei iscritto alle notifiche",
      "tip.state.blocked": "Hai bloccato le notifiche",
      "message.prenotify": "Clicca per iscriverti alle notifiche",
      "message.action.subscribed": "Ti sei iscritto alle notifiche!",
      "message.action.resubscribed": "Ti sei iscritto alle notifiche!",
      "message.action.unsubscribed":
        "Hai annullato l'iscrizione alle notifiche!",
      "dialog.main.title": "Notifica",
      "dialog.main.button.subscribe": "Abbonato",
      "dialog.main.button.unsubscribe": "Annulla l'iscrizione",
      "dialog.blocked.title": "Sblocca le notifiche",
      "dialog.blocked.message":
        "Segui le istruzioni per sbloccare le notifiche:"
    }
  }
};
