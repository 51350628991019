/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
import { SplashScreen } from '@capacitor/splash-screen';
import * as React from "react";
import { I18n, isEmpty, ConsoleLogger as Logger } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";

import { IAuthPieceProps, IAuthPieceState } from "./AuthPiece";
import { isPlatform } from '@ionic/react';
import OneSignal from 'onesignal-cordova-plugin';
import OneSignalWeb from "react-onesignal";

import { IonButton, IonIcon } from '@ionic/react';
import { logIn } from 'ionicons/icons';

import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Button,
  Link
} from "aws-amplify-react";
import LoadingText from "../Loader/LoadingText";

import { toast } from "react-toastify";
import queryString from "query-string";

import AuthPiece from "./AuthPiece";
import Config from "../../config";

const logger = new Logger("SignIn");

export interface ISignInProps extends IAuthPieceProps {
  federated?: any;
  override?: any;
}

export interface ISignInState extends IAuthPieceState {
  loading?: boolean;
  displaySignin: boolean;
}

const SSO_PROVIDER = Config.get("config.ssoProvider");
const isRedirection =
  SSO_PROVIDER === "Doctolib" &&
  !!queryString.parse(window.location.search).identifier;

export class SignIn extends AuthPiece<ISignInProps, ISignInState> {
  constructor(props: ISignInProps) {
    super(props);

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);

    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = { displaySignin: !SSO_PROVIDER, loading: false };
  }

  checkContact(user: any) {
    Auth.verifiedContact(user).then(data => {
      if (!isEmpty(data.verified)) {
        this.changeState("signedIn", user);
        const id_user = user.attributes.sub;
        if (isPlatform('capacitor')) {
          // Register user_id to OneSignal for native app
          OneSignal.setExternalUserId(id_user);
        } else {
          // Register user_id to OneSignal for web app
          OneSignalWeb.setExternalUserId(id_user);
        }
      } else {
        user = Object.assign(user, data);
        this.changeState("verifyContact", user);
      }
    });
  }

  async signIn(event: any) {
    // avoid submitting the form
    if (event) {
      event.preventDefault();
    }

    const username = this.getUsernameFromInput() || "";
    const password = this.inputs.password;

    if (!username)
      return toast.error(I18n.get("Username cannot be empty"), {
        position: "bottom-right",
        hideProgressBar: true
      });

    if (!password)
      return toast.error(I18n.get("emptyPassword"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    this.setState({ loading: true });
    try {
      const user = await Auth.signIn(username, password);
      logger.debug(user);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        logger.debug("confirm user with " + user.challengeName);
        this.changeState("confirmSignIn", user);
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        logger.debug("require new password", user.challengeParam);
        this.changeState("requireNewPassword", user);
      } else if (user.challengeName === "MFA_SETUP") {
        logger.debug("TOTP setup", user.challengeParam);
        this.changeState("TOTPSetup", user);
      } else if (
        user.challengeName === "CUSTOM_CHALLENGE" &&
        user.challengeParam &&
        user.challengeParam.trigger === "true"
      ) {
        logger.debug("custom challenge", user.challengeParam);
        this.changeState("customConfirmSignIn", user);
      } else {
        this.checkContact(user);
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        logger.debug("the user is not confirmed");
        this.changeState("confirmSignUp", { username });
      } else if (err.code === "PasswordResetRequiredException") {
        logger.debug("the user requires a new password");
        this.changeState("forgotPassword", { username });
      } else {
        this.error(err);
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  renderSubmitButton(theme: any) {
    const { displaySignin } = this.state;

    if (SSO_PROVIDER) {
      if (displaySignin) {
        return (
          <Link
            text={I18n.get(Config.get("config.i18n.ssoProviderButton"))}
            theme={{
              ...theme,
              buttonStyle: {
                color: Config.getTheme("mainColor"),
                fontWeight: "bold"
              }
            }}
            onPress={() => {
              Auth.federatedSignIn({ provider: SSO_PROVIDER });
            }}
          />
        );
      }

      return (
        <>
          <Button
            theme={{
              button: {
                ...theme.button,
                backgroundColor: Config.getTheme("mainColor"),
                color: "#FFF"
              }
            }}
            onClick={(e: any) => {
              if (isRedirection) {
                toast.success(I18n.get("Redirection"), {
                  position: "top-center",
                  hideProgressBar: true
                });
              } else {
                e.preventDefault();
                Auth.federatedSignIn({ provider: SSO_PROVIDER });
              }
            }}>
            {I18n.get(Config.get("config.i18n.ssoProviderButton"))}
          </Button>
          <Button
            theme={{
              button: {
                ...theme.button,
                backgroundColor: "#FFF",
                color: Config.getTheme("mainColor"),
                display: SSO_PROVIDER === "Doctolib" ? "none" : ""
              }
            }}
            onClick={() => {
              this.setState({ displaySignin: true });
            }}>
            {I18n.get("Sign in")}
          </Button>
        </>
      );
    }
  }

  showComponent(theme: any) {
    if (isPlatform('capacitor')) {
      SplashScreen.hide();
    }
    const { displaySignin } = this.state;
    const { hide = [] } = this.props;
    if (hide && hide.includes(SignIn)) {
      return null;
    }
    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {I18n.get("Sign in to your account")}
        </SectionHeader>

        {!displaySignin ? this.renderSubmitButton(theme) : null}

        {displaySignin ? (
          <form onSubmit={this.signIn}>
            <SectionBody theme={theme}>
              {this.renderUsernameField(theme)}
              <div>
                <div className="amplify-form-input">
                  <input
                    placeholder={I18n.get("Enter your password")}
                    key="password"
                    type="password"
                    name="password"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionBody>
            <SectionFooter theme={theme}>
              <div>
                <Button
                  theme={theme}
                  type="submit"
                  disabled={this.state.loading}>
                  <LoadingText
                    loadingTitle={I18n.get("connectionLoading")}
                    title={I18n.get("Sign In")}
                    isLoading={this.state.loading}
                  />
                </Button>
              </div>

              <div className="mt-1 login-footer-btn">
                <span className="text text--small auth-text">
                  <Link
                    theme={theme}
                    onClick={() => this.changeState("forgotPassword")}>
                    {I18n.get("Forget your password?")}
                  </Link>
                </span>
                <IonButton onClick={() => this.changeState('signUp')} class="registerBtn" fill="outline">
                  <IonIcon slot="start" icon={logIn}></IonIcon>
                    {I18n.get("I've got a code")}
                </IonButton>
              </div>
            </SectionFooter>
          </form>
        ) : null}
      </FormSection>
    );
  }
}

/**
 * @deprecated use named import
 */
export default SignIn;
