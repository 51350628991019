import * as GenericAPI from "../api/generic";
import * as VideosAPI from "../api/videos";
import { getContentLang } from "./i18n";
import Capsule from "../components/modal/Capsule";
import Video from "../components/modal/Video";
import ReactDOM from "react-dom";
import React from "react";
import CapsuleReward from "../components/modal/CapsuleReward";
import PracticeModal from "../components/modal/PracticeModal";

export const onLearningItemPress = async (
  {
    id_learning_item,
    learning_item_type,
    video_url,
    learning_set_id,
    file_url,
    id_capsule,
    points,
    id_practice,
    practice_title,
    practice_description,
    practice_time
  }: {
    id_learning_item: string;
    learning_item_type: string;
    video_url: string;
    learning_set_id: string;
    file_url: string;
    id_capsule: string;
    points: number;
    id_practice: string;
    practice_title: string;
    practice_description: string;
    practice_time: number;
  },
  idTraining: string,
  updateTrainingProgress?: boolean,
  success?: boolean,
  onFileOpened?: () => void
) => {
  const dataToProps = {
    id_capsule: id_capsule,
    learning_item_id: id_learning_item,
    learning_item_type: learning_item_type,
    learning_set_id: learning_set_id,
    training_id: idTraining,
    points
  };

  if (video_url) {
    try {
      const _videoURL = await VideosAPI.getSignedURL(id_learning_item);
      ReactDOM.render(
        React.createElement(Video, {
          url: _videoURL,
          ...dataToProps,
          success
        }),
        document.getElementById("modal")
      );
    } catch (e) {}
  } else if (file_url) {
    try {
      const fileURL = await GenericAPI.getSignedURL(
        learning_set_id,
        getContentLang(file_url),
        "",
        "learningsets"
      );

      if (!fileURL) return;
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      if (onFileOpened) onFileOpened();

      ReactDOM.render(
        // @ts-ignore
        React.createElement(CapsuleReward, {
          ...dataToProps,
          isCapsule: false,
          success: true
        }),
        document.getElementById("modal")
      );

      // TODO Capsule reward
      // setTimeout(() => Actions.capsuleReward({ success: true, points }), 2000);
    } catch (e) {}
  } else if (learning_item_type === "capsule") {
    ReactDOM.render(
      React.createElement(Capsule, { ...dataToProps, success }),
      document.getElementById("modal")
    );
  } else if (id_practice) {
    ReactDOM.render(
      // @ts-ignore
      React.createElement(PracticeModal, {
        title: practice_title,
        content: practice_description,
        practice_time,
        learning_item_id: id_learning_item,
        learning_item_type,
        learning_set_id,
        training_id: idTraining,
        updateTrainingProgress: updateTrainingProgress || false
      }),
      document.getElementById("modal")
    );
  }
};
